<template>
  <div>
    <search-user @submit="submitSearch" />
    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
    >
      <template slot="images" slot-scope="imgs, record">
        <!--        <span v-if="imgs === null || imgs.length === 0 " />-->
        <!--        <span v-else class="custom-blue" @click="showImg(record)">{{ imgs[0].response.filename }}</span>-->
        <img v-if="imgs && imgs.length > 0" :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
      </template>
      <div slot="effective" slot-scope="effective">
        <span>{{ effective ? '有效': '失效' }}</span>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findTenantUsers } from '@/api/user'
import SearchUser from '@/views/tenants/users/Search'
import { hasPermission } from '@/utils/permission'
import PreviewImage from '@/components/PreviewImage'

export default {
  name: 'UserList',
  components: {
    Pagination,
    PreviewImage,
    SearchUser
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      previewVisible: false,
      previewImages: [],
      pagination: {
        total_count: 0
      }
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  destroyed() {
    this.$EventBus.$off('showImg')
  },
  computed: {
    tenantId() {
      return parseInt(this.$route.params.id)
    },
    columns() {
      return [
        {
          title: '姓名',
          dataIndex: 'emp_name'
        },
        {
          title: '联系方式',
          dataIndex: 'phone_number'
        },
        {
          title: '登录工号',
          dataIndex: 'emp_no'
        },
        {
          title: '状态',
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '照片',
          dataIndex: 'images',
          scopedSlots: { customRender: 'images' }
        }
      ]
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findTenantUsers(Object.assign({ tenant_id: this.tenantId }, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
